(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name persons.controller:DuplicateDialogCtrl
   *
   * @description
   *
   */
  angular
  .module('neo.home.persons.newPerson')
  .controller('DuplicateDialogCtrl', DuplicateDialogCtrl);

  function DuplicateDialogCtrl($scope, $mdDialog, duplicatedPersons, licenses, sports) {

    var filteredDuplicates = {};
    angular.forEach(duplicatedPersons, function (duplicated) {
      if (angular.isUndefined(filteredDuplicates[duplicated.person.id])) {

        filteredDuplicates[duplicated.person.id] = {
          name: duplicated.person.name,
          surname1: duplicated.person.surname1,
          surname2: duplicated.person.surname2,
          bornDate: duplicated.person.bornDate,
          licensesList: duplicated.teamLicences,
          noTeamLicencesList: duplicated.noTeamLicences,
          check: false
        };
      }
      /*filteredDuplicates[person.id].licensesList.push({
       licenseTypeId: person.licenceTypeId,
       organisation: person.organisation,
       team: person.team,
       town: person.town,
       sport: person.sport
       });*/
    });

    $scope.duplicatedPersons = filteredDuplicates;
    $scope.sports = sports;

    $scope.someSelected = function () {
      return _.some($scope.duplicatedPersons, function (dp) {
        return dp.check;
      })
    };

    $scope.unselectOthers = function (id) {
      angular.forEach($scope.duplicatedPersons, function (person, idP) {
        if (idP !== id) person.check = false;

      });
    };

    $scope.parseDate = parseDate;

    $scope.getLicenseName = function (licenseId) {
      var license = findItemById(licenses, licenseId);
      return angular.isDefined(license) ? license.name : undefined;
    };

    $scope.getSportName = function (sportId) {
      var sport = findItemById(sports, sportId);
      return angular.isDefined(sport) ? sport.name : undefined;
    };

    $scope.cancel = function () {
      $mdDialog.cancel();
    };

    $scope.newPerson = function () {
      $mdDialog.hide(undefined);
    };

    $scope.selectedPerson = function () {
      var selectedPerson = _.findKey($scope.duplicatedPersons, function (dp) {
        return dp.check;
      });
      $mdDialog.hide(selectedPerson);
    };

    var findItemById = function (collection, itemId) {
      return _.find(collection, function (i) {
        return i.id === itemId;
      });
    };

    function parseDate(date) {
      var ymd = date.split('-');
      ymd.reverse();
      return ymd.join('-');
    }
  }
}());
